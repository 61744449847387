body {
  /* background-image: url("/assets/images/background.png"); */
  background-position: top center;
  background-size: cover;
  background: url("/assets/images/background.png"),
    linear-gradient(
      268deg,
      #670040 -0.83%,
      #830051 21.85%,
      #250017 71.86%,
      #690041 97.37%
    );

  /* 
    rgba(37, 0, 23, 1)
    rgba(63, 0, 39, 1)
    rgba(103, 0, 64, 1)
    rgba(131, 0, 81, 1)
  */
  /* background-attachment: fixed; */
  /* min-height: 100vh; */
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.material-pdf .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.material-pdf .react-pdf__Page {
  /* max-width: calc(100% - 2em); */
  max-width: 100%;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); */
  /* margin: 1em; */
}

.material-pdf .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.material-pdf .react-pdf__message {
  padding: 20px;
  color: white;
}

.swiper,
.swiper-wrapper {
  /* z-index: unset !important; */
}

.swiper-bullet,
.swiper-pagination-bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px;
  border: solid 1px rgba(254, 254, 254, 0.75);
  background: transparent !important;
}

.swiper-bullet-active,
.swiper-pagination-bullet-active {
  border: solid 1px rgba(254, 254, 254, 1);
  background: #f8f8f8 !important;
}

.swiper-pagination {
  bottom: 0px !important;
  text-align: center;
}
